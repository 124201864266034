import {DataGridModelProps, ModelMeta, createModelMeta, AddDefaultSort} from "@/core-ui/data-grid/compositions";
import { DataGridColumn } from "@/core-ui/types/column";
import { dateFormat } from "@/core-ui/helpers/data-formats";
import RolesDetails from "@/components/users/roles-details";
import { Application } from "@/types/application";
import { computed, ComputedRef } from "vue";
import applicationsService from "@/services/applications-service";

const appTableColumns: DataGridColumn[] = [
    {
        key: "name",
        label: "Application name",
        dataKey: "name",
        sortable: true,
        searchable: true,
        display: {
            table: {
                width: 200,
            },
        },
    },
    {
        key: "active",
        label: "Active",
        dataKey: "revoked",
        sortable: true,
        searchable: true,
        dataTransform: (value: boolean) => (!value ? "Yes" : "No"),
        display: {
            table: {
                width: 200,
            },
        },
    },
    {
        key: "roles",
        label: "Roles",
        dataKey: "roles",
        dataTransform: (roles) => roles.map((r: any) => RolesDetails[r].displayName).join(", "),
        sortable: false,
        searchable: true,
        display: {
            table: {
                width: 230,
            },
        },
    },
    {
        key: "created",
        label: "Created",
        dataKey: "createdAt",
        searchable: true,
        sortable: true,
        dataTransform: dateFormat,
        display: {
            table: {
                width: 180,
            },
        },
    },
    {
        key: "updatedAt",
        label: "Last updated",
        dataKey: "updatedAt",
        dataTransform: dateFormat,
        sortable: true,
        searchable: true,
        display: {
            table: {
                width: 170,
            },
        },
    },
    {
        key: "id",
        label: "ID",
        dataKey: "userId",
        primary: true,
        searchable: true,
        sortable: true,
        display: null,
    },
];

export const columns = appTableColumns;
export type ModalType = Application;
const type = "application";

export const meta: ModelMeta<ModalType> = createModelMeta({
    icon: `raicon-${type}`,
    type,
    display: ({ name, clientId }) => `${name || clientId || ""}`,
});

export const createModelProps = (props: { dataKey: string }, isOnlyCreatedByMe: ComputedRef<boolean> = computed(() => false)): DataGridModelProps<ModalType> => {
    const computedColumns = computed(() => AddDefaultSort(columns, type));
    const finalColumns = computed(() => [
        ...[
            {
                key: "name",
                label: "Application",
                dataKey: "name",
                sortable: true,
                searchable: true,
                display: {
                    table: {
                        width: 200,
                    },
                },
            },
        ],
        ...computedColumns.value,
    ]);
    return {
        get tableName() {
            return type;
        },
        get dataKey() {
            return props.dataKey;
        },
        meta,
        get columns() {
            return finalColumns.value;
        },
        syncServer: false,
        fetchInterval: 60000,
        fetch: async () => {
            let data;
            if (isOnlyCreatedByMe.value) {
                data = await applicationsService.queryByMe();
            } else {
                data = await applicationsService.getApplication();
            }
            return { data };
        },
    };
};
