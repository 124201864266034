
import Modal from "@/core-ui/Modal.vue";
import Note from "@/core-ui/Note.vue";
import Button from "@/core-ui/Button.vue";
import Input from "@/core-ui/forms/components/Input.vue";
import applicationsService from "@/services/applications-service";
import { Application } from "@/types/application";
import { defineComponent, PropType, reactive, ref } from "vue";
import { createEditableApplication } from "@/helpers/applications";
import { copy } from "@/core-ui/helpers/copy";
import { useToast } from "vue-toastification";

const STATUS = {
    SUCCESS: "success",
    FAILED: "failed",
    LOADING: "loading",
    ENSURE: "ensure",
};

export default defineComponent({
    components: { Modal, Button, Note, Input },
    props: {
        application: { type: Object as PropType<Application>, required: true },
        isNew: { type: Boolean, default: true },
        isRegenerate: { type: Boolean, default: false },
        isGroup: Boolean,
    },

    setup(props, ctx) {
        const state = ref<string>(null as any);
        const toast = useToast();
        const editableApplication = ref<Application>(createEditableApplication(props.application));
        const createApplicationResponse = reactive({
            clientId: "",
            applicationName: "",
            secret: { value: "" },
            revoked: false,
        });

        const self = {
            state,
            editableApplication,
            createApplicationResponse,
            get applicationName() {
                return props.application.name;
            },
            get clientId() {
                return props.application.clientId || createApplicationResponse.clientId;
            },
            get secret() {
                return (props.application.secret as any).value;
            },
            copy(text: string) {
                copy(text);
                toast.info("Copied");
            },
            get applicationFromProps() {
                return props.application;
            },
            get title() {
                return props.isNew ? "Create Application" : props.isNew ? "Edit Application" : "Secret Key";
            },

            get isFailed() {
                return state.value == STATUS.FAILED;
            },

            get getCreateApplicationResponse() {
                return createApplicationResponse;
            },

            get isEnsure() {
                return state.value == STATUS.ENSURE;
            },

            get isLoading() {
                return state.value == STATUS.LOADING;
            },

            get isSuccess() {
                return state.value == STATUS.SUCCESS;
            },

            getSecret(clientId: string): Promise<any> {
                return applicationsService.getSecret(clientId);
            },

            newSecret(clientId: string): Promise<any> {
                return applicationsService.newSecret(clientId);
            },

            createNewSecret() {
                const clientId = self.clientId;
                this.newSecret(clientId).then(
                    (res) => {
                        toast.success("New secret created");
                        createApplicationResponse.secret = res.secret;
                        state.value = STATUS.SUCCESS;
                    },
                    (err) => {
                        toast.error("New secret creation failed");
                        state.value = STATUS.FAILED;
                    },
                );
            },
            createOrUpdateApplication() {
                state.value = STATUS.LOADING;
                const func = props.isNew ? applicationsService.createApplication : applicationsService.updateApplication;
                createApplicationResponse.applicationName = editableApplication.value.name;
                func({
                    ...editableApplication.value,
                }).then(
                    (res) => {
                        createApplicationResponse.clientId = res.clientId;
                        this.getSecret(createApplicationResponse.clientId).then(
                            (res) => {
                                createApplicationResponse.secret = res.secret;
                                state.value = STATUS.SUCCESS;
                            },
                            (err) => {
                                state.value = STATUS.FAILED;
                            },
                        );
                    },
                    (err) => {
                        state.value = STATUS.FAILED;
                    },
                );
            },
        };

        if (props.isNew) {
            self.createOrUpdateApplication();
        } else if (props.isRegenerate && self.clientId) {
            const clientId = self.clientId;
            const applicationName = self.applicationName;
            self.getSecret(clientId).then(
                (res) => {
                    createApplicationResponse.applicationName = applicationName;
                    createApplicationResponse.secret = res.secret;
                    state.value = STATUS.SUCCESS;
                },
                (err) => {
                    state.value = STATUS.FAILED;
                },
            );
        } else {
            state.value = STATUS.ENSURE;
        }

        return self;
    },
});
