
import Page from '@/core-ui/Page.vue';
import authStore from '@/stores/authStore';
import settingStore from '@/stores/setting-store';
import DataGrid from '@/core-ui/data-grid/components/DataGrid.vue';
import CreateApplicationDialog from '../components/applications/CreateApplicationDialog.vue';
import {
    useDataGridModel,
    createCRUDActions,
    ActionOn,
    useModalAction,
    handleAction,
    useDeleteModalAction,
} from '@/core-ui/data-grid/compositions';
import { createModelProps as createModelPropsApplication, meta as metaApplication } from '@/models/applications.model';
import EditApplication from '@/components/applications/EditApplication.vue';
import EditRevokeApplication from '@/components/applications/EditRevokeApplication.vue';
import { computed, defineComponent, ref } from 'vue';
import { useToast } from 'vue-toastification';
import applicationsService from '@/services/applications-service';
import DeleteModalAction from '@/core-ui/data-grid/components/DeleteModal/DeleteModalAction.vue';

export default defineComponent({
    components: {
        Page,
        DataGrid,
        DeleteModalAction,
        CreateApplicationDialog,
        EditApplication,
        EditRevokeApplication,
    },
    setup() {
        const toast = useToast();
        const isOnlyCreatedByMeFilter = computed(() => !authStore.isAdmin && authStore.isDepartmentAdmin);
        const modelApplications = useDataGridModel(createModelPropsApplication({ dataKey: metaApplication.type }, isOnlyCreatedByMeFilter));
        const initModalApplications = useModalAction({
            submit: modelApplications.refresh,
            mapHandleData: (item = {}) => ({ ...item }),
        });

        const initEditRevokeModalApplications = useModalAction({
            submit: modelApplications.update,
            mapHandleData: (item = {}) => ({ ...item }),
        });

        async function deleteItemsApplications(itemsToDelete?: any[]) {
            if (!itemsToDelete) {
                return;
            }
            return Promise.all(
                itemsToDelete.map((application: any) => applicationsService.deleteApplication(application.clientId)),
            );
        }
        const editModalApplications = useModalAction({
            submit: async (application) => {
                if (application && !application.clientId) {
                    // init the application
                    await handleAction(
                        'Initalize application',
                        initModalApplications.handle.bind(null, {
                            application: {
                                name: application.name,
                                roles: application.roles,
                                permittedClusters: application.permittedClusters,
                                permitAllClusters: application.permitAllClusters,
                                tenantId: application.tenantId,
                                revoked: application.revoked,
                                isNew: true,
                            },
                        }) as any,
                        toast,
                    );
                }
                return modelApplications.refresh();
            },
            mapHandleData: (item = {}) => ({ ...item }),
        });
        const revokeModalApplications = useDeleteModalAction<any>({
            modelMeta: metaApplication,
            delete: (props) =>
                deleteItemsApplications(props).then(() => {
                    modelApplications.refresh();
                }),
        });
        const deleteModalApplications = useDeleteModalAction<any>({
            modelMeta: metaApplication,
            delete: (props) =>
                deleteItemsApplications(props).then(() => {
                    modelApplications.refresh();
                }),
        });
        function permitted() {
            return authStore.userInfo.roles.includes('admin') || authStore.userInfo.roles.includes('department_admin');
        }

        return {
            currentTab: ref('Applications'),
            editModalApplications,
            initModalApplications,
            initEditRevokeModalApplications,
            settingStore,
            deleteModalApplications,
            revokeModalApplications,
            modelApplications,
            get isNew() {
                return !editModalApplications.handleData.clientId;
            },
            get actionsApplications() {
                const actions = [
                    ...createCRUDActions(
                        metaApplication,
                        {
                            create: editModalApplications.handle,
                            update: editModalApplications.handle,
                            remove: deleteModalApplications.handle as any,
                        },
                        permitted,
                    ),
                ];

                actions.push({
                    on: ActionOn.Item,
                    func: (application: any) => {
                        return initModalApplications.handle({
                            application,
                            isNew: false,
                            isRegenerate: true,
                        });
                    },
                    key: 'regenerate secret',
                    label: 'Secret Key',
                    icon: 'raicon-reset',
                    permitted,
                });

                actions.push({
                    on: ActionOn.Item,
                    func: (application: any) => {
                        return initEditRevokeModalApplications.handle({
                            application,
                        });
                    },
                    key: 'restore',
                    label: 'Restore',
                    icon: 'raicon-revoke',
                    filter: (application: any) => application.revoked,
                    permitted,
                });

                actions.push({
                    on: ActionOn.Item,
                    func: (application: any) => {
                        return initEditRevokeModalApplications.handle({
                            application,
                        });
                    },
                    key: 'revoke',
                    label: 'Revoke',
                    icon: 'raicon-revoke',
                    filter: (application: any) => !application.revoked,
                    permitted,
                });

                return actions;
            },
        };
    },
});
