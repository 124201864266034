
import { DisplayDensityOptions } from "@/core-ui/data-grid/types";
import { defineComponent, PropType } from "vue";

export default defineComponent({
    props: {
        headerStyle: Object,
        flex: { type: Boolean, default: true },
        density: {
            type: String as PropType<DisplayDensityOptions>,
            default: DisplayDensityOptions.Comfortable,
        },
    },
});
