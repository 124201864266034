import apiClient from "./api-client";
import { Application } from "@/types/application";

class ApplicationsService {
    private _rolesPromise = undefined as any;

    getApplication(): Promise<Array<Application>> {
        return apiClient.asyncRequestWithResponse(`v1/k8s/apps`, "GET") as Promise<Array<Application>>;
    }

    queryByMe(): Promise<Array<Application>> {
        return apiClient.asyncRequestWithResponse(`v1/k8s/apps?onlyCreatedByMe=true`, "GET") as Promise<Array<Application>>;
    }

    getAllRoles(): Promise<Array<any>> {
        if (!this._rolesPromise) {
            this._rolesPromise = apiClient.asyncRequestWithResponse(`v1/k8s/users/roles`, "GET") as Promise<Array<any>>;
        }
        return this._rolesPromise;
    }

    getUserRoles(userId: string): Promise<Array<any>> {
        return apiClient.asyncRequestWithResponse(`v1/k8s/users/${userId}/roles`, "GET") as Promise<Array<any>>;
    }

    deleteApplication(clientID: string) {
        return apiClient.asyncRequestWithoutResponse(`v1/k8s/apps/${clientID}`, "DELETE");
    }

    createApplication(application: Application) {
        return apiClient.asyncRequestWithResponseAndError(`v1/k8s/apps`, "POST", application);
    }

    getSecret(clientID: string) {
        return apiClient.asyncRequestWithResponseAndError(`v1/k8s/apps/${clientID}/secret`, "GET");
    }

    newSecret(clientID: string) {
        return apiClient.asyncRequestWithResponseAndError(`v1/k8s/apps/${clientID}/secret`, "POST");
    }

    updateApplication(application: Application) {
        return apiClient.asyncRequestWithoutResponse(`v1/k8s/apps/${application.clientId}`, "PUT", application);
    }
}

const applicationsService = new ApplicationsService();
export default applicationsService;
