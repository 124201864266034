
import Button from "@/core-ui/Button.vue";
import Modal from "@/core-ui/Modal.vue";
import CircleLoader from "@/core-ui/CircleLoader.vue";
import { dispatcher, RequestState } from "@/core-ui/services/dispatcher";
import { Application } from "@/types/application";
import InputField from "@/core-ui/forms/components/InputField.vue";
import { emailValidation, nameValidation, passwordValidation } from "@/core-ui/helpers/validations-schemas";
import applicationsService from "@/services/applications-service";
import RolesDetails from "./../users/roles-details";
import FormOptionsBox from "@/components/applications/FormOptionsBox.vue";
import clustersStore from "@/stores/clusters-store";
import { createEditableApplication } from "@/helpers/applications";
import { defineComponent, PropType, ref, watch } from "vue";
import authStore, { RolesEnum } from "@/stores/authStore";

export default defineComponent({
    components: {
        FormOptionsBox,
        InputField,
        Modal,
        Button,
        CircleLoader,
    },
    emits: ["submit", "cancel"],
    props: {
        isNew: Boolean,
        application: Object as PropType<Application>,
    },

    setup(props, ctx) {
        const submitted = ref<boolean>(false);
        const editingApplication = ref<Application>(null as any);

        watch(
            () => props.application,
            () => {
                editingApplication.value = createEditableApplication(props.application);
            },
            { immediate: true },
        );

        const applicationRolesLoaded = ref<boolean>(true);

        const self = {
            submitted,
            editingApplication,
            applicationRolesLoaded,
            title: (props.isNew ? "New" : "Edit") + " Application",
            emailValidation,
            nameValidation,
            passwordValidation,
            rolesRequest: dispatcher.dispatch(applicationsService.getAllRoles()) as RequestState,

            get clusters() {
                return (clustersStore.state.clusters || []).map((cluster) => {
                    return {
                        id: cluster.uuid,
                        title: cluster.name,
                    };
                });
            },

            get rolesOptions() {
                const isDepartmentAdmin =
                    !authStore.userInfo.roles.includes("admin") && authStore.userInfo.roles.includes("department_admin");
                const roles = this.rolesRequest.results || [];
                return roles
                    .filter((role: any) => {
                        if (isDepartmentAdmin) {
                            return role == RolesEnum.RESEARCHER || role == RolesEnum.ML_ENGINEER;
                        }
                        return true;
                    })
                    .map((role: any) => {
                        return {
                            id: role,
                            title: RolesDetails[role].displayName,
                            description: RolesDetails[role].description,
                            aid: "application-role-checkbox-" + role,
                        };
                    });
            },

            get invalid(): boolean {
                return !editingApplication.value.name;
            },

            get clusterSelectedValid() {
                return (
                    editingApplication.value.permitAllClusters || !!editingApplication.value.permittedClusters?.length
                );
            },

            get hasAdminRole() {
                const roles = this.rolesRequest.results || [];
                const applicationRoles: any = editingApplication.value ? editingApplication.value.roles : [];
                const adminRole = roles.find((role: any) => role.name === "admin");
                return adminRole && applicationRoles.includes(adminRole.id);
            },

            cancel() {
                ctx.emit("cancel");
            },

            async submit() {
                submitted.value = true;

                try {
                    if (!props.isNew) {
                        delete editingApplication.value.userId;
                        delete editingApplication.value.createdAt;
                        delete editingApplication.value.updatedAt;
                        await dispatcher.executeWithErrorHandling(
                            applicationsService.updateApplication(editingApplication.value),
                        );
                    }
                    ctx.emit("submit", editingApplication.value);
                    self.cancel();
                } catch (e) {
                    submitted.value = false;
                }
            },
        };

        watch(
            () => self.hasAdminRole,
            () => {
                if (self.hasAdminRole) {
                    editingApplication.value.permitAllClusters = true;
                }
            },
        );
        return self;
    },
});
