import { Application } from "@/types/application";

export function createEditableApplication(application?: Application) {
    if (!application || !Object.keys(application).length) {
        return {
            name: "",
            password: "",
            email: "",
            roles: [],
            permittedClusters: [],
            permitAllClusters: true,
            revoked: false,
        };
    } else {
        const editableApplication = {
            roles: [],
            permittedClusters: [],
            ...(application as any),
        };
        return editableApplication;
    }
}
