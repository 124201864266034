export type RoleDetails = {
    displayName: string;
    description: string;
};

export const RolesDetails: { [key: string]: RoleDetails } = {
    admin: {
        displayName: "Administrator",
        description: "Can view all screens, and manage users, user permissions and clusters.",
    },
    department_admin: {
        displayName: "Department Admin",
        description: "Can view managed departments, create and manage projects, add researchers and ML engineers and assign them to projects.",
    },
    editor: {
        displayName: "Editor",
        description: "Can view all screens, and manage projects.",
    },
    researcher: {
        displayName: "Researcher",
        description: "Can be assigned to individual projects.",
    },
    research_manager: {
        displayName: "Research Manager",
        description: "Automatically assigned to all projects",
    },
    viewer: {
        displayName: "Viewer",
        description: "Can view all screens.",
    },
    ml_engineer: {
        displayName: "ML Engineer",
        description: "Can view and manage deployments and cluster resources.",
    },
};

export default RolesDetails;
