
import Button from "@/core-ui/Button.vue";
import Modal from "@/core-ui/Modal.vue";
import { dispatcher, RequestState } from "@/core-ui/services/dispatcher";
import { Application } from "@/types/application";
import applicationsService from "@/services/applications-service";
import { createEditableApplication } from "@/helpers/applications";
import { defineComponent, PropType, Ref, ref, watch } from "vue";

export default defineComponent({
    components: {
        Modal,
        Button,
    },
    emits: ["submit", "cancel"],
    props: {
        application: { type: Object as PropType<Application>, required: true },
    },
    setup(props, ctx) {
        const editingApplication = ref<Application>({} as Application);

        watch(
            () => props.application,
            () => {
                props.application && (editingApplication.value = createEditableApplication(props.application));
            },
            { immediate: true },
        );
        const clicked = ref(false);

        const self = {
            clicked,
            get title() {
                return self.isRevoked ? "Restore Application" : "Revoke Application";
            },
            get text() {
                return self.isRevoked ? "Restore Application" : "Revoke Application";
            },
            get isRevoked() {
                return props.application?.revoked;
            },
            cancel() {
                ctx.emit("cancel");
            },
            async onclickR() {
                if (clicked.value) return;

                clicked.value = true;
                try {
                    delete editingApplication.value.userId;
                    delete editingApplication.value.createdAt;
                    delete editingApplication.value.updatedAt;
                    editingApplication.value.revoked = !props.application.revoked;
                    await dispatcher.executeWithErrorHandling(
                        applicationsService.updateApplication(editingApplication.value),
                    );
                    ctx.emit("submit", editingApplication.value);
                    self.cancel();
                } finally {
                    clicked.value = false;
                }
            },
        };

        return self;
    },
});
