import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("table", {
    class: _normalizeClass({ [_ctx.$style.table]: true, [_ctx.$style.tableFlex]: _ctx.flex })
  }, [
    (_ctx.$slots.header)
      ? (_openBlock(), _createElementBlock("thead", _hoisted_1, [
          _createElementVNode("tr", {
            class: _normalizeClass(_ctx.$style.tableHeader),
            style: _normalizeStyle(_ctx.headerStyle),
            ref: "header"
          }, [
            _renderSlot(_ctx.$slots, "header")
          ], 6)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("tbody", {
      class: _normalizeClass([_ctx.$style.tableBody, _ctx.$style[_ctx.density]])
    }, [
      _renderSlot(_ctx.$slots, "body")
    ], 2)
  ], 2))
}