import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DeleteModalAction = _resolveComponent("DeleteModalAction")!
  const _component_EditRevokeApplication = _resolveComponent("EditRevokeApplication")!
  const _component_EditApplication = _resolveComponent("EditApplication")!
  const _component_CreateApplicationDialog = _resolveComponent("CreateApplicationDialog")!
  const _component_DataGrid = _resolveComponent("DataGrid")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, null, {
    default: _withCtx(() => [
      _createVNode(_component_DeleteModalAction, { state: _ctx.deleteModalApplications }, null, 8, ["state"]),
      (_ctx.initEditRevokeModalApplications.isOpening)
        ? (_openBlock(), _createBlock(_component_EditRevokeApplication, {
            key: 0,
            application: _ctx.initEditRevokeModalApplications.handleData.application,
            onCancel: _ctx.initEditRevokeModalApplications.cancel,
            onSubmit: _ctx.initEditRevokeModalApplications.submit
          }, null, 8, ["application", "onCancel", "onSubmit"]))
        : _createCommentVNode("", true),
      (_ctx.editModalApplications.isOpening)
        ? (_openBlock(), _createBlock(_component_EditApplication, {
            key: 1,
            application: _ctx.editModalApplications.handleData,
            "is-new": _ctx.isNew,
            onCancel: _ctx.editModalApplications.cancel,
            onSubmit: _ctx.editModalApplications.submit
          }, null, 8, ["application", "is-new", "onCancel", "onSubmit"]))
        : _createCommentVNode("", true),
      (_ctx.initModalApplications.isOpening)
        ? (_openBlock(), _createBlock(_component_CreateApplicationDialog, {
            key: 2,
            application: _ctx.initModalApplications.handleData.application,
            "is-group": false,
            "is-new": _ctx.initModalApplications.handleData.isNew,
            "is-regenerate": _ctx.initModalApplications.handleData.isRegenerate,
            onCancel: _ctx.initModalApplications.cancel
          }, null, 8, ["application", "is-new", "is-regenerate", "onCancel"]))
        : _createCommentVNode("", true),
      _createVNode(_component_DataGrid, {
        model: _ctx.modelApplications,
        actions: _ctx.actionsApplications,
        "sync-url": true
      }, null, 8, ["model", "actions"])
    ]),
    _: 1
  }))
}